import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	CloudinaryImage,
	VideoButton,
	Modal,
	VideoElement,
} from "@website-builder/ui/shared/elements";
import { VideoPlayerWrapper } from "./styles";

const VideoPlayer = (props) => {
	const {
		videoImageUrl,
		mediaUrl,
		altTag,
		className,
		isDarkBg = false,
		haveModal = true,
		onClick = () => {},
		isNonActive,
		...restProps
	} = props;
	const [openVideoModal, setOpenVideoModal] = useState(false);
	return (
		<VideoPlayerWrapper
			className={className}
			isDarkBg={isDarkBg}
			{...restProps}
		>
			<div className="media-container">
				<CloudinaryImage url={videoImageUrl} lazyload alt={altTag} />
				<VideoButton
					isDarkBg={isDarkBg}
					disabled={isNonActive}
					className="video-button"
					onClick={() => {
						setOpenVideoModal(true);
						onClick(mediaUrl);
					}}
				/>
			</div>
			{haveModal ? (
				<Modal
					className="video-modal"
					show={openVideoModal}
					disableBackdropClick={true}
					onClose={() => setOpenVideoModal(false)}
					media
				>
					{openVideoModal && <VideoElement url={mediaUrl} />}
				</Modal>
			) : (
				<></>
			)}
		</VideoPlayerWrapper>
	);
};

VideoPlayer.propTypes = {
	videoImageUrl: PropTypes.string,
	altTag: PropTypes.string,
	mediaUrl: PropTypes.string,
	className: PropTypes.string,
	isDarkBg: PropTypes.bool,
	onClick: PropTypes.func,
};

export default VideoPlayer;
