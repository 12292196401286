import styled from "styled-components";
import {
	TABLET_M_BP,
	MOBILE_SM_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
export const VideoPlayerWrapper = styled.div`
	.video-modal {
		width: calc(100% - 30vw);
		height: calc(100% - 10rem);
		padding-top: 10vw;
	}
	.video-modal .closeButtonWrapper {
		top: 8vw;
	}
	& .media-container {
		position: relative;
	}
	& .video-button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 2;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.video-modal {
			width: calc(100% - 2rem);
			height: auto;
			padding: 20vh 0;
		}
		.video-modal .closeButtonWrapper {
			top: 10vh;
			right: 0;
		}
	}
	@media screen and (orientation: landscape) and (min-device-width: ${MOBILE_SM_BP}px) and (max-device-width: ${TABLET_M_BP}px) {
		.video-modal {
			width: calc(100% - 5rem);
			height: auto;
			padding: 15vw 5vw;
		}
		.video-modal .closeButtonWrapper {
			top: 15vh;
		}
	}
`;
